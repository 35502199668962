import PropTypes from 'prop-types'
import React from 'react'
import classNames from 'classnames'
import { Input, InputAdornment, styled } from '@mui/material'
import LocationIcon from '@mui/icons-material/LocationOnOutlined'
import { breakPoints } from '@constants/styles'

const StyledInput = styled(Input)({
  margin: 0,
  backgroundColor: 'white',
  width: '95%',
  border: 'none',
  '& .MuiInputBase-input': {
    padding: '8px !important',
  },
  '&::placeholder': {
    [`@media screen and (max-width: ${breakPoints.small})`]: {
      fontSize: '0.8rem',
    },
  },
})
const StyledLocationIcon = styled(LocationIcon)({
  marginLeft: '10%',
})

const DeliveryInput = ({ inputRef, placeholderText, error, addressInput, onChange, noAutoComplete, id }) => (
  <div className="delivery-input" style={{ padding: 0 }}>
    <label className="label-header" htmlFor={`location${id}`}>
      {error && (
        <div className="error">
          <p>{placeholderText}</p>
        </div>
      )}
    </label>

    <StyledInput
      ref={inputRef}
      type="text"
      className={classNames(
        'input-field',
        {
          error,
        },
        'shipping',
      )}
      name="location"
      id={`location${id}`}
      aria-required="true"
      aria-invalid={!!error}
      placeholder={placeholderText}
      value={addressInput}
      onChange={onChange}
      autoComplete={noAutoComplete ? 'off' : ''}
      startAdornment={
        <InputAdornment position="start">
          <StyledLocationIcon />
        </InputAdornment>
      }
    />
  </div>
)

DeliveryInput.propTypes = {
  addressInput: PropTypes.any,
  error: PropTypes.any,
  id: PropTypes.any,
  inputRef: PropTypes.any,
  noAutoComplete: PropTypes.any,
  onChange: PropTypes.func,
  placeholderText: PropTypes.string,
}

export default DeliveryInput
