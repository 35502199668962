import React, { useState, useMemo } from 'react'
import { object } from 'prop-types'
import { graphql } from 'gatsby'
import { SliderFilterListWrapper, HeadingStyle, categoryTileSliderProps, defaultSliderProps } from './index.styles'
import FilterPills from './FilterPills'
import StrapiSliderV2 from '../Slider-v2'

const SliderFilterList = ({ data }) => {
  const [pillIndex, setPillIndex] = useState(0)
  const { Heading, DisplayHeading, SliderFilters, Spacing, MobileSpacing, testId, Title } = data?.SliderFilterList || {}

  // Filters and the slider list are filtered to prevent a pill when no slider or slides exist
  const sliderHasSlides = item => item.Slider?.Slider?.Slides.length
  const filters = useMemo(() => SliderFilters?.filter(sliderHasSlides).map(item => item.FilterName) ?? [], [
    SliderFilters,
  ])
  const sliderArray = useMemo(() => SliderFilters?.filter(sliderHasSlides) ?? [], [SliderFilters])

  // Determines props to send to slider
  const isCategoryTileSlider = sliderArray?.[pillIndex]?.Slider?.Slider?.Slides?.[0]?.CategoryTile
  const sliderProps = {
    ...defaultSliderProps,
    ...(isCategoryTileSlider ? categoryTileSliderProps : {}),
  }

  return (
    <SliderFilterListWrapper Spacing={Spacing} MobileSpacing={MobileSpacing} data-testid={testId}>
      {DisplayHeading && <HeadingStyle variant="h2">{Heading}</HeadingStyle>}
      <FilterPills filters={filters} pillIndex={pillIndex} setPillIndex={setPillIndex} sliderFilterListTitle={Title} />
      {sliderArray?.[pillIndex]?.Slider && (
        <StrapiSliderV2
          data={sliderArray[pillIndex].Slider}
          sliderProps={sliderProps}
          key={`filtered-slider-${pillIndex}`}
          selectedPill={filters?.[pillIndex]}
          sliderFilterListTitle={Title}
        />
      )}
    </SliderFilterListWrapper>
  )
}

export default SliderFilterList

SliderFilterList.propTypes = {
  data: object,
}

export const SliderFilterListFragment = graphql`
  fragment SliderFilterListFragment on StrapiSliderFilterList {
    DisplayHeading
    Heading
    testId
    Title
    Spacing {
      BottomPadding
      TopPadding
    }
    MobileSpacing {
      BottomPadding
      TopPadding
    }
    SliderFilters {
      FilterName
      Slider {
        Slider {
          ...StrapiSliderfragment
        }
      }
    }
  }
`
