import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { styled, Grid, Box } from '@mui/material'

import ImageWithSku from '../ImageWithSku'
import StrapiMarkdown from '../Markdown'

const SubsectionWrapperStyles = styled('div')(({ theme }) => ({
  margin: '48px auto 0',
  [theme.breakpoints.down('sm')]: {
    margin: '32px auto',
  },
}))

const TextWrapperStyles = styled(Grid)(({ theme }) => ({
  margin: 'auto',
  textAlign: 'center',
}))

const ParagraphHeadingStyles = styled('h3')(({ theme }) => ({
  color: '#07263B',
  fontSize: '25px',
  fontWeight: 500,
  lineHeight: '34px',
  margin: '0 33px 8px',
  textAlign: 'left',
  [theme.breakpoints.down('sm')]: {
    fontSize: '20px',
    lineHeight: '28px',
    margin: '16px 0 10px',
  },
}))

const ParagraphBodyStyles = styled(Grid)(({ theme }) => ({
  color: '#151515',
  fontWeight: 300,
  lineHeight: '24px',
  textAlign: 'left',
  padding: '0 28px',
  '& p': {
    color: '#151515',
    fontSize: '18px',
  },
  [theme.breakpoints.down('sm')]: {
    padding: 0,
  },
}))

const BlogSubsection = ({ data, odd }) => {
  const { testId, Heading, Body, Image } = data?.BlogSubsection || {}
  const markdown = Body?.Markdown
  return (
    <SubsectionWrapperStyles data-testid={testId}>
      <Grid container spacing={0} direction={odd ? 'row-reverse' : 'row'}>
        <Grid item xs={12} sm={6}>
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            <ImageWithSku data={Image} height={464} width={696} />
          </Box>
          <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
            <ImageWithSku data={Image} height={238} width={358} />
          </Box>
        </Grid>
        <TextWrapperStyles item xs={12} sm={6}>
          <ParagraphHeadingStyles>{Heading}</ParagraphHeadingStyles>
          <ParagraphBodyStyles>
            <StrapiMarkdown gridWrapper={false} data={markdown} />
          </ParagraphBodyStyles>
        </TextWrapperStyles>
      </Grid>
    </SubsectionWrapperStyles>
  )
}

export default BlogSubsection

BlogSubsection.propTypes = {
  data: PropTypes.object,
  odd: PropTypes.bool,
}

export const BlogSubsectionFragment = graphql`
  fragment BlogSubsectionFragment on BaseBlogSubsection {
    BlogSubsection {
      testId
      Heading
      Body {
        Markdown {
          ...StrapiPageMarkdownFragment
        }
      }
      Image {
        ...BaseImageWithSkuFragment
      }
    }
  }
`
