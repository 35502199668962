import React, { useState, useEffect } from 'react'

import PropTypes from 'prop-types'
import { styled, Box, Grid } from '@mui/material'
import { validateCouponFromLocalStorage } from '@services/coupon'

import { navigate } from '@helpers/link'
import Placeholder from './Placeholder'

const ContentLoader = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #f7f7f7;
  z-index: 100;
  height: 100%;
`

export default function ValidateCoupon({ link }) {
  const redirectTo = link?.InternalUrl || link?.ExternalUrl || '/'
  const [isValid, setIsValid] = useState(false)

  useEffect(() => {
    if (validateCouponFromLocalStorage()) {
      setIsValid(true)
    } else {
      navigate(redirectTo, { replace: true })
    }
  }, [redirectTo])

  if (!isValid) {
    return (
      <>
        <ContentLoader>
          <Grid container spacing={4}>
            <Grid item sm={12}>
              <Placeholder width="100%" height="30vh" />
            </Grid>
            <Grid item sm={4}>
              <Placeholder width="100%" height="25vh" />
            </Grid>
            <Grid item sm={4}>
              <Placeholder width="100%" height="25vh" />
            </Grid>
            <Grid item sm={4}>
              <Placeholder width="100%" height="25vh" />
            </Grid>
          </Grid>
        </ContentLoader>
        <style
          dangerouslySetInnerHTML={{
            __html: `
            html, body, main {
              overflow: hidden;
            }
            main#content {
              position: relative;
              height: 60vh;
              overflow: hidden;
            }
        `,
          }}
        />
      </>
    )
  }
  return null
}

ValidateCoupon.propTypes = {
  link: PropTypes.object.isRequired,
}
