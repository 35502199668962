import { createContext, useContext } from 'react'

const CommonQuestionsCxt = createContext({
  activeNav: '',
  setActiveNav: () => {},
  activeQuestionId: '',
  setActiveQuestionId: () => {},
  drawerWidth: 260,
  data: [],
  offset: 0,
  sectionRefs: [],
  setSectionRefs: () => {},
})

export const CommonQuestionsProvider = CommonQuestionsCxt.Provider

export const useCommonQuestionsCxt = () => useContext(CommonQuestionsCxt) || {}
