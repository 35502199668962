import React from 'react'
import { array, func, number, string } from 'prop-types'
import BaseSwiper from '../../../../@rtg2022/components/organisms/BaseSwiper/BaseSwiper'
import FilterPill from './FilterPill'
import { FilterPillsWrapper } from './FilterPills.styles'

const FilterPills = ({ pillIndex, setPillIndex, filters, sliderFilterListTitle }) => {
  const handleFilterClick = index => {
    setPillIndex(index)
    window.dataLayer.push({
      event: 'SliderFilterList pill click',
      selectedPill: filters?.[index],
      sliderFilterListTitle,
    })
  }

  const categorySlides = filters.map((filter, index) => (
    <FilterPill
      key={filter}
      title={filter}
      pillIndex={pillIndex}
      index={index}
      handleFilterClick={e => handleFilterClick(index)}
    />
  ))

  return (
    <FilterPillsWrapper>
      <BaseSwiper
        slides={categorySlides}
        spaceBetween={10}
        hideScrollbar
        fullWidthMobile
        fullWidthMobileOffset={15}
        breakpoints={{
          0: {
            slidesPerView: 3.4,
          },
          680: {
            slidesPerView: 4.4,
          },
          1024: {
            slidesPerView: 'auto',
          },
        }}
      />
    </FilterPillsWrapper>
  )
}

export default FilterPills

FilterPills.propTypes = {
  pillIndex: number,
  setPillIndex: func,
  filters: array,
  sliderFilterListTitle: string,
}
