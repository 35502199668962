import { styled, Typography } from '@mui/material'

export const categoryTileSliderProps = {
  breakpoints: {
    0: {
      slidesPerView: 1.4,
    },
    680: {
      slidesPerView: 2.4,
    },
    1024: {
      slidesPerView: 3,
    },
    1250: {
      slidesPerView: 4,
    },
    1500: {
      slidesPerView: 5,
    },
  },
}

export const defaultSliderProps = {
  forceHideTitle: true,
  forceNoSpacing: true,
  forceNoViewAllLink: true,
  forceDisplayDesktopAndMobile: true,
  forceScrollbarDesktopAndMobile: true,
  fullWidthMobile: true,
}

export const SliderFilterListWrapper = styled('div')(({ theme, Spacing, MobileSpacing }) => ({
  paddingTop: Spacing?.TopPadding ?? 0,
  paddingBottom: Spacing?.BottomPadding ?? 0,
  [theme.breakpoints.down('sm')]: {
    paddingTop: `${MobileSpacing?.TopPadding ?? 0}px !important`,
    paddingBottom: `${MobileSpacing?.BottomPadding ?? 0}px !important`,
  },
}))

export const HeadingStyle = styled(Typography)(({ theme }) => ({
  textTransform: 'none',
  '&.MuiTypography-root': {
    fontWeight: 500,
    fontSize: '25px',
    lineHeight: '33px',
    color: '#003566',
    margin: 0,
    letterSpacing: 'normal',
    marginBottom: '25px',
    [theme.breakpoints.down('md')]: {
      fontSize: '20px',
      lineHeight: '26px',
      marginBottom: '20px',
    },
  },
}))
