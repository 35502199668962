// dependencies
import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import classNames from 'classnames'

const useStyles = makeStyles({
  skeleton: {
    display: 'inline-block',
    position: 'relative',
    overflow: 'hidden',
    backgroundColor: 'rgba(180, 180, 180, 0.6)',
    cursor: 'wait',
    '&:after': {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      transform: 'translateX(-100%)',
      backgroundImage: `
        linear-gradient(
          90deg, 
          rgba(242,242,242, 0) 0, 
          rgba(242,242,242, 0.2) 20%, 
          rgba(242,242,242, 0.5) 60%, 
          rgba(242,242,242, 0)
        )`,
      animation: '$shimmer 1s infinite',
      content: '""',
    },
  },
  '@keyframes shimmer': {
    '100%': {
      transform: 'translateX(100%)',
    },
  },
})

const getValue = value => {
  if (value) {
    if (typeof value === 'string') {
      return value
    }
    return `${value}px`
  }
  return '100%'
}

const Placeholder = ({ height, width, className }) => {
  const classes = useStyles()
  return (
    <div
      className={classNames(classes.skeleton, className)}
      style={{
        height: getValue(height),
        width: getValue(width),
      }}
    />
  )
}

Placeholder.propTypes = {
  className: PropTypes.string,
  height: PropTypes.any,
  width: PropTypes.any,
}

export default Placeholder
