import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { getCurrentLocation, getRegionZone, getStateAbbr } from '@helpers/geo-location'
import { getLocation } from '@services/location'
import { useStaticQuery, graphql } from 'gatsby'
import { styled, Modal } from '@mui/material'
import SlickSlider from '@rtgdev/react-slick'
import FLLogo from '@assets/images/state_FL_desktop.svg'
import GALogo from '@assets/images/state_GA_desktop.svg'
import TXLogo from '@assets/images/state_TX_desktop.svg'
import SliderArrow from './Slider/SliderArrow'

const stateLogos = { FL: FLLogo, GA: GALogo, TX: TXLogo }

const FlyerBlock = props => {
  const StyledDiv = styled('div')(({ theme }) => ({
    '.slide img': {
      margin: '0 auto',
    },
    'button.slick-arrow': {
      width: '50px',
      height: '50px',
      '&:before': {
        color: 'black',
        fontSize: '48px',
      },
    },
    [theme.breakpoints.down('md')]: {
      width: '125%',
      marginLeft: '-12.5%',
      marginRight: '-12.5%',
    },
  }))

  let { slides } = props
  slides = Array.isArray(slides) ? slides : [slides]

  const settings = {
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SliderArrow variant="next" />,
    prevArrow: <SliderArrow variant="previous" />,
  }

  return (
    <StyledDiv>
      <SlickSlider {...settings}>
        {slides &&
          slides.map((slide, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <div className="slide" key={i}>
              <img src={slide.url} alt={slide.alternativeText} />
            </div>
          ))}
      </SlickSlider>
    </StyledDiv>
  )
}

FlyerBlock.propTypes = {
  slides: PropTypes.array,
}

const zipCodeMap = require('../../../lib/constants/zipcode-map.json')

const StyledDiv = styled('div')`
  min-width: 50%;
  background: #fff;
  height: auto;
  padding: 0px;
  position: relative;
  .close-modal-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    border: 0px;
    background: transparent;
    cursor: pointer;
    padding: 0 6px;
    line-height: 26px;
  }

  .modal-header {
    padding: 15px;
    border-bottom: 1px solid #e5e5e5;
    h4 {
      color: #0a0a0a;
      margin: 10px;
    }
    p {
      color: #0a0a0a;
      margin: 0 10px;
      font-size: 14px;
    }
  }

  .modal-body {
    padding: 20px;
    label,
    input,
    select {
      display: inline-block;
      width: 80%;
    }
    select {
      padding: 4px;
    }
    .errors {
      color: red;
    }
    .hidden {
      display: none;
    }
  }

  .modal-footer {
    button {
      float: right;
      display: inline-block;
      vertical-align: middle;
      padding: 8px 16px;
      background: #4183d7;
      border: 0;
      cursor: pointer;
      color: #fff;
      border-radius: 3px;
      text-decoration: none;
      transition: background 0.3s ease;
      margin: 10px;
    }
  }
`

const ZipForm = React.forwardRef(function ZipForm(props, ref) {
  const [zip, setZip] = useState('')
  const [errors, setErrors] = useState(false)
  const [market, setMarket] = useState('')
  const { setLocationData, setOpen } = props

  const handleLocationChange = loc_info => setLocationData(loc_info.response)

  const handleChangeZip = event => setZip(event.target.value)

  const handleChangeMarket = event => setMarket(event.target.value)

  const handleSubmit = async event => {
    event.preventDefault()
    if ((zip.length < 5 || Number.isNaN(zip)) && market === '') {
      setErrors(true)
      return false
    }

    let locData = {}
    if (zip.length < 5 || Number.isNaN(zip)) locData = await getLocation(market)
    else locData = await getLocation(zip)

    handleLocationChange(locData)
    setOpen(false)
    return false
  }

  return (
    <StyledDiv>
      <div className="modal-header">
        <h4>Change Location</h4>
        <p>Enter your zip code or select your market:</p>
      </div>
      <div className="modal-body">
        <div className="location-modal">
          <form noValidate="" className="" onSubmit={handleSubmit}>
            <label htmlFor="zip">Your zip code</label>
            <input
              className="form-control "
              id="zip"
              name="zip"
              onChange={handleChangeZip}
              value={zip}
              pattern="[0-9]{5}"
              placeholder="5 Digit Zip Code"
              type="text"
            />
            <label htmlFor="market">Your Market</label>
            <select className="form-control" onChange={handleChangeMarket}>
              <option value="">--Select--</option>
              {zipCodeMap
                .sort((a, b) => (a.city < b.city ? -1 : 1))
                .map((loc, i) => (
                  <option key={loc.storeZip} value={loc.storeZip}>
                    {loc.city}
                  </option>
                ))}
            </select>
            <div className={errors ? 'errors alert alert-danger' : 'hidden'}>Must be 5 digit zip code.</div>
          </form>
        </div>
        <button onClick={() => setOpen(false)} className="close-modal-btn" type="button">
          ✖
        </button>
      </div>
      <div _ngcontent-c1="" className="modal-footer">
        <button _ngcontent-c1="" className="btn btn-default" type="button" onClick={handleSubmit}>
          Save
        </button>
      </div>
    </StyledDiv>
  )
})

ZipForm.propTypes = {
  setLocationData: PropTypes.func,
  setOpen: PropTypes.func,
}

// static query to grab all specials to filter on after user input
const getSpecialsQuery = graphql`
  query GetSepcials {
    allStrapiSpecial(sort: { order: ASC, fields: id }, filter: { strapi_id: { ne: 0 } }) {
      nodes {
        id
        Title
        Flyers {
          alternativeText
          url
        }
      }
    }
  }
`

const Container = styled('div')(({ theme }) => ({
  width: '80%',
  margin: '0 auto',
  position: 'relative',

  h1: {
    color: '#02317d',
    fontSize: '24px',
    fontWeight: 600,
    paddingBottom: '10px',
    paddingTop: '15px',
    [theme.breakpoints.down('md')]: {
      width: '50%',
      marginLeft: 0,
      paddingTop: 0,
      textAlign: 'left',
      minHeight: '68px',
      fontSize: '14px',
      display: 'flex',
      alignItems: 'center',
    },
  },
  '.container': {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'center',
    },
  },
  '.store-region-pane': {
    position: 'absolute',
    top: 0,
    background: '#00529f',
    color: '#fff',
    padding: '4px 8px',

    [theme.breakpoints.down('md')]: {
      right: 0,
    },

    '.row': {
      display: 'flex',
      alignItems: 'center',
    },
    '.loc-small': {
      fontSize: '10px',
      lineHeight: '1.5em',
      backgroundImage: 'none !important',
      [theme.breakpoints.down('md')]: {
        span: {
          display: 'none',
        },
      },
    },
    '.change-location': {
      background: '#00529f',
      color: '#fff',
      fontSize: '24px',
      fontWeight: 500,
      border: 0,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      height: '60px',
      padding: '8px 4px',
      backgroundPosition: '0px 8px',
    },
    '.change-location-desc': {
      marginLeft: '10px',
      fontSize: '14px',
      background: '#00529f',
      color: '#fff',
      border: 0,
      div: {
        lineHeight: '16px',
      },
      [theme.breakpoints.down('md')]: {
        width: '80px',
      },
    },
  },
}))

const StrapiSpecials = ({ data }) => {
  // location based on user interaction
  const [locationData, setLocationData] = useState(null)
  // state for the zip form modal being open
  const [open, setOpen] = useState(false)

  // used for display region name on page
  const { region } = getRegionZone()
  const fullRegionName = {
    SE: 'Southeast',
    FL: 'Florida',
    TX: 'Texas',
    OOM: 'Out of Market',
  }
  // used to set inital state location
  const location = getCurrentLocation()
  location.stateAbbr = getStateAbbr(location.state)

  // content from initial strapi query
  const pageContent = data.Specials
  // other specials used when user changes location
  const { allStrapiSpecial } = useStaticQuery(getSpecialsQuery)
  const allSpecials = allStrapiSpecial?.nodes.filter(special => special.Title)
  // the special visible after user changes location
  const [currSpecial, setCurrSpecial] = useState({})

  useEffect(() => {
    // the user has changed their location
    if (locationData) {
      locationData.stateAbbr = getStateAbbr(locationData.state)
      let tempFlyer = allSpecials.filter(
        spec => spec.Title.indexOf(`${locationData.stateAbbr} ${locationData.city}`) > -1,
      )[0]
      // if there is no special for the specific location, grab the region specific special
      if (!tempFlyer) {
        ;[tempFlyer] = allSpecials.filter(spec => spec.Title.indexOf(`${locationData.region}`) > -1)
      }
      setCurrSpecial(tempFlyer)
    } else {
      // use the region specified by cookie
      setCurrSpecial(allSpecials.filter(spec => spec.Title.indexOf(region) > -1)[0])
    }
  }, [locationData, allSpecials, region])

  return (
    <Container>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <ZipForm setLocationData={setLocationData} setOpen={setOpen} />
      </Modal>
      <div className="container">
        <div className="store-region-pane full-size">
          <div className="row">
            <button
              className="change-location"
              onClick={() => {
                setOpen(true)
              }}
              style={{
                backgroundImage: `url('${stateLogos[locationData ? locationData.stateAbbr : location.stateAbbr]}')`,
              }}
              type="button"
            >
              {(locationData && locationData.stateAbbr) || (location && location.stateAbbr)}
            </button>
            <button className="change-location-desc" type="button" onClick={() => setOpen(true)}>
              <div>CHANGE LOCATION</div>
              <div
                className="loc-small"
                style={{
                  backgroundImage: `url('${stateLogos[locationData ? locationData.stateAbbr : location.stateAbbr]}')`,
                }}
              >
                <span>
                  View Outlet products in your area
                  <br />
                  My location: {(locationData && locationData.city) || (location && location.city)}
                </span>
              </div>
            </button>
          </div>
        </div>
      </div>
      <h1>{locationData ? `${locationData.city} ${locationData.state}` : fullRegionName[region]}</h1>
      {locationData ? (
        <FlyerBlock slides={currSpecial.Flyers} />
      ) : (
        pageContent?.Flyers && <FlyerBlock slides={pageContent.Flyers} />
      )}
    </Container>
  )
}

StrapiSpecials.propTypes = {
  data: PropTypes.object,
}

export default StrapiSpecials

// initial query from strapi showing the special based on region
export const query = graphql`
  fragment StrapiSpecialsFragment on StrapiSpecial {
    Flyers {
      caption
      alternativeText
      name
      url
    }
  }
`
