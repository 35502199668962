export const throttle = (callback, wait) => {
  let time = Date.now()
  return (...args) => {
    if (time + wait - Date.now() < 0) {
      callback(...args)
      time = Date.now()
    }
  }
}

export const mergeRefs = (...refs) => {
  const filteredRefs = refs.filter(Boolean)
  if (!filteredRefs.length) return null
  if (filteredRefs.length === 1) return filteredRefs[0]
  return inst => {
    filteredRefs.forEach(ref => {
      if (typeof ref === 'function') {
        ref(inst)
      } else if (ref) {
        ref.current = inst
      }
    })
  }
}
