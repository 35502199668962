import { styled } from '@mui/material'

export const PillWrapper = styled('div')(({ theme }) => ({ // eslint-disable-line
  '& .MuiChip-label': {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '19px',
    padding: '7px 10px',
    transition: 'background-color 0.05s ease, color 0.05s ease 0.05s',
  },
  '& .MuiChip-filled': {
    border: '1px solid rgba(0, 83, 160, 0.7)',
  },
  '& .MuiChip-filled:hover': {
    backgroundColor: theme.palette.primary.main,
  },
  [theme.breakpoints.down('md')]: {
    '& .MuiChip-root': {
      width: '100%',
    },
  },
}))
