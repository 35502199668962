import React from 'react'
import { func, number, string } from 'prop-types'
import { Chip } from '@mui/material'
import { PillWrapper } from './FilterPill.styles'

const FilterPill = ({ title, index, pillIndex, handleFilterClick }) => {
  const isSelected = index === pillIndex
  return (
    <PillWrapper>
      <Chip
        label={title}
        color="primary"
        variant={isSelected ? 'filled' : 'outlined'}
        onClick={() => handleFilterClick(title)}
      />
    </PillWrapper>
  )
}

export default FilterPill

FilterPill.propTypes = {
  title: string,
  index: number,
  pillIndex: number,
  handleFilterClick: func,
}
