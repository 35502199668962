import React, { useState, useEffect } from 'react'
import { string } from 'prop-types'
import { fetchProductBySku } from '@services/product'
import { analyticsProduct, addToDataLayer_nextgen } from '@helpers/google-tag-manager'
import { useProduct } from './hooks'
import { useCart } from '../cart/hooks'
import QuickViewModal from '../../@rtg2022/components/organisms/QuickViewModal/QuickViewModal'

function ProductQuickView({ sku, componentPage = 'product-quick-view' }) {
  const [product, setProduct] = useState({})
  const [showQuickView, setShowQuickView] = useState(true)
  const { region, zone, stockMessage } = useProduct({ product })
  const { renderAddToCartModal: RenderAddToCartModal, onAddToCart } = useCart({
    quantity: 1,
    product,
    componentPage,
    stockMessage,
    warrantyEnabled: false,
    showLocation: false,
    moreInfoButton: false,
    source: 'PLA Modal',
    index: 0,
  })

  useEffect(() => {
    fetchProductBySku(sku).then(data => setProduct(data))
  }, [sku])

  const closeModal = reason => {
    if (reason === 'closeButton') {
      addToDataLayer_nextgen('pla_modal_close')
    }
    if (reason === 'Navigated to PDP') {
      addToDataLayer_nextgen('ee_click', {
        ecommerce: {
          click: {
            position: '',
            list: 'PLA Modal See Full Details',
            products: [analyticsProduct(product, 1)],
          },
        },
      })
    }
    setShowQuickView(false)
  }

  if (!product?.sku) return null

  return (
    <>
      <RenderAddToCartModal />
      <QuickViewModal
        isOpen={showQuickView}
        closeModal={closeModal}
        product_json={product}
        price_zone={`${region}_${zone}`}
        addToCart={onAddToCart}
      />
    </>
  )
}

ProductQuickView.propTypes = {
  sku: string,
  componentPage: string,
}

export default React.memo(ProductQuickView)
